@import './sass/mixins';
@import './sass/variables';

section {
    padding: 100px 10vw 130px;
    max-width: 1400px;
}

section:nth-child(odd) {
    background-color: $secondary-background-color;
    color: $secondary-font-color;
}

section:nth-child(even) {
    background-color: $main-background-color;
    color: $main-font-color;
}

.checklist__wrapper {
    text-align: center;
}

.checklist__wrapper h1 {
    font-size: xx-large;
}

.checklist__wrapper {

    button {
        @include default-button;
    }
}

.checklist__overlay {
    @include position;
    background-color: rgba(0,0,0, 0.5);
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.15s;
}

.dark {
    opacity: 1;
}

.checklist__modal {
    background-color: white;
    color:black;
    position: fixed;
    z-index: 300;
    width: 100%;
    height: 90%;
    max-width: 700px;
    opacity: 0;
    transition: all 0.5s;
    text-align: center;
}

.active {
    transform: translateY(0);
    opacity: 1;
}

.checklist__container {
    text-align: left;
    position: absolute;
    z-index: 300;
    overflow-y: scroll;
    top: 80px;
    bottom: 30px;
    width: 100%;
    display: grid;
}

#checklist__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    padding: 5px;
    color: black;
    background-color: transparent;
    border: 1px solid black;
    transition: all 0.3s;
    z-index: 500;
}

#checklist__close-button:hover {
    background-color: rgba(0,0,0,0.15);
    transform: scale(1.1);
}


/* Tablet layout */
@media (min-width: 751px) and (max-width: 1200px) {
    section {
        padding: 15vh 10vw 25vh;
    }
}


/* Mobile layout */
@media (max-width: 750px) {
    section {
        padding: 10vh 10vw 15vh;
    }
}

/* Mobile layout */
@media (max-width: 500px) {
    .checklist__container {
        top: 130px;
        bottom: 60px;
    }

    section {
        padding: 10vh 3vw 15vh;
    }
}