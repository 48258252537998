@import 'variables';

@mixin default-button($border-color: $primary-border-button-color, $button-color: $primary-button-color, 
$font-color: $primary-font-button-color, $side-padding: 20px, $border-radius: 5px) {
    
    padding: 15px $side-padding;
    text-transform: uppercase;
    font-size: medium;
    border: 3px solid $border-color;
    border-radius: $border-radius;
    background-color: $button-color;
    color: $font-color;
    transition: all 0.5s;

    &:hover {
        background-color: transparent;
        color: $button-color;
        cursor: pointer;
    }
}

@mixin position($position: fixed, $top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}