@import '../sass/variables';

.profiles__container {
    box-shadow: $box-shadow;
    background-color: $main-background-color;
    border-radius: 10px;
    padding: 3vh 1vw;
    transition: all 0.5s;
    display: grid;
    grid-template-rows: 30px 200px 10px 1fr;
    align-items: center;
    justify-items: center;

    h3, h6 {
        margin: 0;
    }

    .profiles__image {
        width: 100%;
        height: 100%;
        max-width: 150px;
        max-height: 150px;
        border-radius: 100%;
        background-color: gray;
        margin: 20px; 
    
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: contain;
        }
    }
}

/* Tablet layout */
@media (min-width: 751px) and (max-width: 1200px) {
    .profiles__container {
        grid-template-rows: 30px 225px 10px 1fr;

        .profiles__image {
            max-width: 175px;
            max-height: 175px;
        }
    }
}

/* Mobile layout */
@media (max-width: 750px) {
    .profiles__container {
        grid-template-rows: 30px 250px 10px 1fr;

        .profiles__image {
            max-width: 200px;
            max-height: 200px;
        }
    }
}