@import '../sass/variables';

$border: 1px solid $separation-line-color;

.accordion__item {
   border-bottom: $border; 

   &:first-of-type {
        border-top: $border; 
   }

   .accordion__header {
        background-color: $accordion-header-color;
        color: $accordion-font-color;
        cursor: pointer;
        padding: 1rem;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 1.2rem;
        transition: background-color 0.25s;
        display: grid;
        grid-template-columns: 1fr 30px;
        gap: 20px;

        &:hover {
            background-color: $accordion-header-hovered-color;  
        }

        h4 {
            margin: auto 0;
        }
    }

    
    .accordion__content {
        max-height: 0; 
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;

        p {
            padding: 1rem;
        }

        ul {
            margin-top: -1rem;
            padding-bottom: 1rem;
        }

    }

}
