.team__title {
    font-size: xx-large;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5vh;
}

.profiles__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    text-align: center;
}

/* Tablet layout */
@media (min-width: 751px) and (max-width: 1200px) {
    .profiles__wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

/* Mobile layout */
@media (max-width: 750px) {
    .profiles__wrapper {
        grid-template-columns: 1fr;
        margin: 0 5vw;
    }
}
