.accordion {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .accordion__title {
        text-transform: uppercase;
        font-size: 200%;
    }
}
