@import './sass/variables';

body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    background-color: $main-background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.6;
}
