@import '../sass/mixins';
@import '../sass/variables';

.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: center;
    justify-content: center;

    .about__video {
        width: 100%;
        height: 400px;
    }

    .about__video--wrapper {
        width: 100%;
        height: auto;
        min-width: 230px;
        max-height: 500px;
    }

    button {
        @include default-button;
        margin-top: 20px;
    }

}

/* Mobile layout */
@media (max-width: 750px) {
    .about {
        grid-template-columns: 1fr;
    }
}