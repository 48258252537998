@import '../sass/mixins';
@import '../sass/variables';

.footer {
    background-color: $navigation-bar-color;
    color: $navigation-font-color;
    padding: 5vh 5vw;
    margin: auto 0;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    button {
        @include default-button($primary-font-button-color, $primary-font-button-color, $primary-button-color, 30px, 100px);
        margin: 20px 0;
        font-size: 16px;
    }

    .social__grid {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
    
        .social__container {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            
            &:hover {
                cursor: pointer;
            }
    
            .social__image {
                width: 100%;
                border-radius: 100%;
            }
        }
    }
}



