.checklist__content-wrapper {
    background-color: rgba(0,0,0,0.1);
    margin: 0 10px;
    border-radius: 5px;

    .checklist__content {
        margin: 0 15px;
    }

    .checklist__title {
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 5px;
    }
    
    .checklist__list {
        list-style-type: none;

        .checklist__steps {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: auto 1fr;
            margin-left: -40px;
            margin-bottom: 5px;

            input[type="checkbox"] {
                margin-right: 20px;
            }
        }
        
    }
}



