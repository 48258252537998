// Header Navigation
$navigation-bar-color: #0E4D64;
$navigation-font-color: #F8ECC2;

// Body sections
$main-background-color: #fafafa;
$secondary-background-color: #F8ECC2;
$main-font-color: #5B5F97;
$secondary-font-color: #0E4D64;

// Style for accordion components
$border-color: #0E4D64;
$separation-line-color: #0E4D64;
$accordion-header-color: transparent;
$accordion-header-hovered-color: #A8DADC;
$accordion-font-color: #5B5F97;

// Style for button
$primary-button-color: #229f9f;
$primary-border-button-color: #229f9f;
$primary-font-button-color: #F8ECC2;


// Error Handling
$success-color: #00c22d;
$error-color: #e00000;

// Style for boxes
$box-shadow: 0px 0px 10px rgba(98, 61, 0, 0.25);