.tiers__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
}

.tiers__container {
    padding: 15px 3vw;
    transition: all 0.5s;
    padding-top: 15px;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba(0,0,0,0.3);
}

.tiers__container:first-of-type {
    border-top: 2px solid rgba(0,0,0,0.3);
}

.tiers__container h3, .tiers__container h6 {
    margin: 0;
}

.list__grid {
    color: white;
    text-align: left;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    gap: 15px;
}

.list__grid li {
    background-color: rgb(50, 50, 50);
    position: relative;
    border-radius: 10px;
}

.image__container {
    width: 100%;
    min-height: 200px;
    max-height: 250px;
    position: relative;
    display: flex;          
    align-items: center;    
    justify-content: center; 
    overflow: hidden;
    border-radius: 10px;
    opacity: 0.4; 
}

.service__image {
    width: 100%;
    height: auto;       
    object-fit: cover;
    transition: all 0.5s;
    display: flex;          
    align-items: center;    
    justify-content: center; 
}

.service__label {
    padding: 10px;
    position: absolute;
    top: 0;
}

.service__button {
    padding: 10px;
    position: absolute;
    right: 10px;
    bottom: 15px;
    border: 2px solid white;
    color: white;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s;
}

.service__button:hover {
    color: black;
    background-color: white;
    cursor: pointer;
}

.tiers__quickstart {
    margin-top: 45px;
}

.tiers__quickstart h1 {
    margin-top: 15px;
    margin-bottom: -30px;
    padding: 0;
    transform: rotate(90deg);
    font-size: 500%;
}

.tiers__quickstart h1:hover {
    cursor: pointer;
}

/* Tablet layout */
@media (min-width: 501px) and (max-width: 900px) {
    .list__grid {
        grid-template-columns: 1fr 1fr;
    }
}

/* Mobile layout */
@media (max-width: 500px) {
    .list__grid {
        grid-template-columns: 1fr;
    }    
}